export type AppConfigType = {
  Settings: {
    Server: {
      graphql: {
        apiClient: {
          baseUrl: string;
          timeout: number;
        };
      };
      rest: {
        apiClient: {
          baseUrl: string;
          timeout: number;
        };
        imagesClient: {
          baseUrl: string;
          timeout: number;
        };
      };
    };
  };
  Modules: object;
  Components: object;
};

export const AppConfig: AppConfigType = {
  Settings: {
    Server: {
      graphql: {
        apiClient: {
          baseUrl: process.env.NEXT_PUBLIC_SERVER_BASE_URL!,
          timeout: 30000,
        },
      },
      rest: {
        apiClient: {
          baseUrl: process.env.NEXT_PUBLIC_SERVER_BASE_URL!,
          timeout: 30000,
        },
        imagesClient: {
          baseUrl: process.env.NEXT_PUBLIC_IMAGES_BASE_URL!,
          timeout: 30000,
        },
      },
    },
  },
  Modules: {},
  Components: {},
};
