import extractFiles from "extract-files/extractFiles.mjs";
import isExtractableFile from "extract-files/isExtractableFile.mjs";
import FormDataNode from "form-data";
import {RequestMiddleware, Variables} from "graphql-request";

/**
 * Duck type if NodeJS stream
 * https://github.com/sindresorhus/is-stream/blob/3750505b0727f6df54324784fe369365ef78841e/index.js#L3
 */
const isExtractableFileEnhanced = (value: Blob | unknown): value is Blob =>
  isExtractableFile(value) ||
  (value !== null && typeof value === "object" && "pipe" in value && typeof value.pipe === "function");

/**
 * Returns Multipart Form as body if body contains files
 * (https://github.com/jaydenseric/graphql-multipart-request-spec)
 * Otherwise returns JSON
 */
export function multipartExtractFiles<V extends Variables = Variables>(
  request: Parameters<RequestMiddleware<V>>[0]
): ReturnType<RequestMiddleware<V>> {
  const {body, variables} = request;
  if (!body || !variables) {
    return request;
  }
  const parsedBody = JSON.parse(body.toString());
  const {query} = parsedBody;
  const {clone, files} = extractFiles({query, variables}, isExtractableFileEnhanced);

  if (files.size === 0) {
    return request;
  }

  const Form = typeof FormData === "undefined" ? FormDataNode : FormData;

  const form = new Form();

  form.append("operations", JSON.stringify(clone));

  const map: {[key: number]: string[]} = {};
  let i = 0;
  files.forEach((paths) => {
    map[++i] = paths;
  });
  form.append("map", JSON.stringify(map));

  i = 0;
  files.forEach((_paths, file) => {
    form.append(`${++i}`, file);
  });

  // Map Required headers
  let headers: HeadersInit = {};
  if (request.headers && request.operationName) {
    const filteredHeadersEntries = Object.entries(request.headers).filter(([key, _value]) => key !== "Content-Type");
    headers = {
      ...Object.fromEntries(filteredHeadersEntries),
      "x-apollo-operation-name": request.operationName,
      "Apollo-Require-Preflight": "true",
    };
  }
  const newRequest: ReturnType<RequestMiddleware<V>> = {
    ...request,
    headers,
    body: form as FormData,
  };
  return newRequest;
}
