import {TypedDocumentNode as DocumentNode} from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> = T | {[P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  Byte: {input: any; output: any};
  DateTime: {input: any; output: any};
  Date: {input: any; output: any};
  Time: {input: any; output: any};
  Point: {input: any; output: any};
  JSON: {input: any; output: any};
  JSONObject: {input: any; output: any};
  Upload: {input: any; output: any};
  Void: {input: any; output: any};
};

export type Query = {
  __typename?: "Query";
  _?: Maybe<Scalars["Void"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
  me?: Maybe<User>;
  user?: Maybe<User>;
  users: UserConnection;
  file?: Maybe<File>;
  files: FileConnection;
  logs: LogConnection;
  statistics: Statistics;
  publicConfigs: PublicConfigConnection;
  tapReport: TapReport;
  tapReports: TapReportConnection;
  taps: TapConnection;
};

export type QueryUserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<UserBackofficeStatus>>;
  role?: InputMaybe<Array<UserBackofficeRole>>;
  orderBy?: InputMaybe<UserOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryFileArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryFilesArgs = {
  filter?: InputMaybe<FileFilter>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  extension?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<FileBackofficeStatus>>;
  orderBy?: InputMaybe<FileOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLogsArgs = {
  filter?: InputMaybe<LogFilter>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  source?: InputMaybe<Array<LogBackofficeSource>>;
  level?: InputMaybe<Array<LogBackofficeLevel>>;
  orderBy?: InputMaybe<LogOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPublicConfigsArgs = {
  filter?: InputMaybe<PublicConfigFilter>;
  orderBy?: InputMaybe<PublicConfigOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryTapReportArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTapReportsArgs = {
  filter?: InputMaybe<TapReportFilter>;
  orderBy?: InputMaybe<TapReportOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryTapsArgs = {
  filter?: InputMaybe<TapFilter>;
  orderBy?: InputMaybe<TapOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  _?: Maybe<Scalars["Void"]["output"]>;
  authenticate?: Maybe<AccessToken>;
  /** @deprecated Use authenticate with Credentials Authentication Type */
  signIn?: Maybe<AccessToken>;
  /** @deprecated Use authenticate with Credentials Authentication Type */
  signUp?: Maybe<AccessToken>;
  refreshTokens?: Maybe<AccessToken>;
  checkEmail?: Maybe<CheckEmail>;
  confirmEmail?: Maybe<Scalars["Void"]["output"]>;
  resendEmailConfirmation?: Maybe<Scalars["Void"]["output"]>;
  requestResetPassword?: Maybe<Scalars["Void"]["output"]>;
  resetPassword?: Maybe<Scalars["Void"]["output"]>;
  updatePassword?: Maybe<Scalars["Void"]["output"]>;
  signOut?: Maybe<Scalars["Void"]["output"]>;
  createUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  deleteUser: Scalars["Boolean"]["output"];
  removeMe: Scalars["Boolean"]["output"];
  uploadUrl: FileUploadUrl;
  uploadOverwriteUrl: FileUploadUrl;
  finishUpload: File;
  downloadUrl: FileDownloadUrl;
  updateFile?: Maybe<File>;
  deleteFile: Scalars["Boolean"]["output"];
  createLog?: Maybe<Log>;
  registerUserDevice?: Maybe<RegisteredUserDevice>;
  testPushNotification?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  createPublicConfig?: Maybe<PublicConfig>;
  updatePublicConfig?: Maybe<PublicConfig>;
  deletePublicConfig: Scalars["Boolean"]["output"];
  approveTapReports: TapReportsData;
  rejectTapReports: TapReportsData;
};

export type MutationAuthenticateArgs = {
  type: AuthenticationBackofficeType;
  identity: Scalars["String"]["input"];
  payload: AuthenticationPayload;
};

export type MutationSignInArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  generateRefreshToken?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationSignUpArgs = {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  upload?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type MutationRefreshTokensArgs = {
  token: Scalars["String"]["input"];
};

export type MutationCheckEmailArgs = {
  email: Scalars["String"]["input"];
};

export type MutationConfirmEmailArgs = {
  token: Scalars["String"]["input"];
};

export type MutationResendEmailConfirmationArgs = {
  email: Scalars["String"]["input"];
};

export type MutationRequestResetPasswordArgs = {
  email: Scalars["String"]["input"];
};

export type MutationResetPasswordArgs = {
  token: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationUpdatePasswordArgs = {
  oldPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
};

export type MutationSignOutArgs = {
  deviceId: Scalars["ID"]["input"];
};

export type MutationCreateUserArgs = {
  user: CreateUserInput;
  image?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type MutationUpdateUserArgs = {
  id: Scalars["ID"]["input"];
  user: UpdateUserInput;
  image?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveMeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUploadUrlArgs = {
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  extension?: InputMaybe<Scalars["String"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  isPrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
  tags?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type MutationUploadOverwriteUrlArgs = {
  id: Scalars["ID"]["input"];
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type MutationFinishUploadArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDownloadUrlArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFileArgs = {
  id: Scalars["ID"]["input"];
  file: UpdateFileInput;
};

export type MutationDeleteFileArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateLogArgs = {
  log: CreateLogInput;
};

export type MutationRegisterUserDeviceArgs = {
  userDevice: RegisterUserDeviceInput;
};

export type MutationCreatePublicConfigArgs = {
  publicConfig: CreatePublicConfigInput;
};

export type MutationUpdatePublicConfigArgs = {
  id: Scalars["ID"]["input"];
  publicConfig: UpdatePublicConfigInput;
};

export type MutationDeletePublicConfigArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationApproveTapReportsArgs = {
  tapId: Scalars["ID"]["input"];
};

export type MutationRejectTapReportsArgs = {
  tapId: Scalars["ID"]["input"];
};

export type Subscription = {
  __typename?: "Subscription";
  _?: Maybe<Scalars["Void"]["output"]>;
};

export type ConnectionPageInfo = {
  __typename?: "ConnectionPageInfo";
  hasNextPage: Scalars["Boolean"]["output"];
  endCursor?: Maybe<Scalars["String"]["output"]>;
};

export enum ConnectionOrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type EnumFilterInput = {
  isAsText?: InputMaybe<Scalars["String"]["input"]>;
  isNotAsText?: InputMaybe<Scalars["String"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DateTimeFilterInput = {
  eq?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StringFilterInput = {
  eq?: InputMaybe<Scalars["String"]["input"]>;
  eqi?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  containsi?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IdFilterInput = {
  eq?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ArrayStringFilterInput = {
  eq?: InputMaybe<Array<Scalars["String"]["input"]>>;
  eqi?: InputMaybe<Array<Scalars["String"]["input"]>>;
  overlap?: InputMaybe<Array<Scalars["String"]["input"]>>;
  contains?: InputMaybe<Array<Scalars["String"]["input"]>>;
  containsi?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ArrayIdFilterInput = {
  eq?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  overlap?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  contains?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ArrayIntFilterInput = {
  eq?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  overlap?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  contains?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ArrayFloatFilterInput = {
  eq?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  overlap?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  contains?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IntFilterInput = {
  ne?: InputMaybe<Scalars["Int"]["input"]>;
  eq?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FloatFilterInput = {
  ne?: InputMaybe<Scalars["Float"]["input"]>;
  eq?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BooleanFilterInput = {
  ne?: InputMaybe<Scalars["Boolean"]["input"]>;
  eq?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CheckEmail = {
  __typename?: "CheckEmail";
  isAvailable?: Maybe<Scalars["Boolean"]["output"]>;
  isBlacklisted?: Maybe<Scalars["Boolean"]["output"]>;
  isCorporate?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AccessToken = {
  __typename?: "AccessToken";
  userId: Scalars["String"]["output"];
  userRole: UserBackofficeRole;
  authorizationType: AuthorizationType;
  token?: Maybe<Scalars["String"]["output"]>;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type AuthenticationPayloadForCredentials = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  upload?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type AuthenticationPayload = {
  credentials?: InputMaybe<AuthenticationPayloadForCredentials>;
};

export enum AuthenticationBackofficeType {
  Credentials = "CREDENTIALS",
}

export enum AuthorizationType {
  Internal = "INTERNAL",
}

export type User = {
  __typename?: "User";
  id: Scalars["ID"]["output"];
  firstName: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<UserBackofficeStatus>;
  role?: Maybe<UserBackofficeRole>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  imageData?: Maybe<Scalars["Byte"]["output"]>;
  nickName?: Maybe<Scalars["String"]["output"]>;
  area?: Maybe<Scalars["String"]["output"]>;
  birthday?: Maybe<Scalars["Date"]["output"]>;
  bio?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type UserImageDataArgs = {
  height?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges: Array<UserEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserEdge = {
  __typename?: "UserEdge";
  node: User;
  cursor: Scalars["String"]["output"];
};

export type CreateUserInput = {
  firstName: Scalars["String"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  role?: InputMaybe<UserBackofficeRole>;
  nickName?: InputMaybe<Scalars["String"]["input"]>;
  area?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  bio?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<UserBackofficeRole>;
  nickName?: InputMaybe<Scalars["String"]["input"]>;
  area?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  bio?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserOrder = {
  field: UserOrderField;
  direction: ConnectionOrderDirection;
};

export type UserFilter = {
  firstName?: InputMaybe<StringFilterInput>;
  lastName?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<EnumFilterInput>;
  role?: InputMaybe<EnumFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum UserOrderField {
  Firstname = "FIRSTNAME",
  Lastname = "LASTNAME",
  Email = "EMAIL",
  Status = "STATUS",
  Role = "ROLE",
  Nickname = "NICKNAME",
  Area = "AREA",
  Birthday = "BIRTHDAY",
  Bio = "BIO",
  Country = "COUNTRY",
  CreatedAt = "CREATED_AT",
  UpdatedAt = "UPDATED_AT",
}

export enum UserBackofficeStatus {
  Pending = "PENDING",
  Active = "ACTIVE",
  Blocked = "BLOCKED",
}

export enum UserBackofficeRole {
  Basic = "BASIC",
  Trusted = "TRUSTED",
  Administrator = "ADMINISTRATOR",
}

export type PublicUser = {
  __typename?: "PublicUser";
  id: Scalars["ID"]["output"];
  firstName: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  imageData?: Maybe<Scalars["Byte"]["output"]>;
  nickName?: Maybe<Scalars["String"]["output"]>;
  bio?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
};

export type PublicUserImageDataArgs = {
  height?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FileUploadUrl = {
  __typename?: "FileUploadUrl";
  id: Scalars["ID"]["output"];
  method: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  headers?: Maybe<Scalars["JSONObject"]["output"]>;
  fields?: Maybe<Scalars["JSONObject"]["output"]>;
  contentType: Scalars["String"]["output"];
  expirationDate: Scalars["DateTime"]["output"];
};

export type FileDownloadUrl = {
  __typename?: "FileDownloadUrl";
  id: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
  expirationDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type File = {
  __typename?: "File";
  id: Scalars["ID"]["output"];
  deviceId?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
  path?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  extension?: Maybe<Scalars["String"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["Int"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  isPrivate?: Maybe<Scalars["Boolean"]["output"]>;
  privateData?: Maybe<FilePrivateData>;
  status: FileBackofficeStatus;
  statusDate: Scalars["DateTime"]["output"];
  statusMessage?: Maybe<Scalars["String"]["output"]>;
  storageType: FileBackofficeStorageType;
  storageId?: Maybe<Scalars["String"]["output"]>;
  storageUploadUrl?: Maybe<Scalars["String"]["output"]>;
  storageDownloadUrl?: Maybe<Scalars["String"]["output"]>;
  thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: Scalars["ID"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: Scalars["ID"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<Scalars["ID"]["output"]>;
};

export type FilePrivateData = {
  __typename?: "FilePrivateData";
  userId?: Maybe<Scalars["String"]["output"]>;
  status: FileBackofficePrivateStatus;
  statusDate: Scalars["DateTime"]["output"];
  statusMessage?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
};

export type FileConnection = {
  __typename?: "FileConnection";
  edges: Array<FileEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FileEdge = {
  __typename?: "FileEdge";
  node: File;
  cursor: Scalars["String"]["output"];
};

export type UpdateFileInput = {
  notes?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type FileOrder = {
  field: FileOrderField;
  direction: ConnectionOrderDirection;
};

export type FileFilter = {
  deviceId?: InputMaybe<StringFilterInput>;
  userId?: InputMaybe<StringFilterInput>;
  path?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  extension?: InputMaybe<StringFilterInput>;
  notes?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<EnumFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum FileOrderField {
  Device = "DEVICE",
  User = "USER",
  Path = "PATH",
  Name = "NAME",
  Extension = "EXTENSION",
  PrivateStatus = "PRIVATE_STATUS",
  Status = "STATUS",
  CreatedAt = "CREATED_AT",
  UpdatedAt = "UPDATED_AT",
}

export enum FileBackofficeStatus {
  PendingUpload = "PENDING_UPLOAD",
  Ready = "READY",
  Invalid = "INVALID",
}

export enum FileBackofficePrivateStatus {
  Default = "DEFAULT",
  Status1 = "STATUS1",
  Status2 = "STATUS2",
}

export enum FileBackofficeStorageType {
  FileSystem = "FILE_SYSTEM",
  AmazonS3 = "AMAZON_S3",
}

export type Log = {
  __typename?: "Log";
  id: Scalars["ID"]["output"];
  source: LogBackofficeSource;
  userId?: Maybe<Scalars["String"]["output"]>;
  level: LogBackofficeLevel;
  title: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type LogConnection = {
  __typename?: "LogConnection";
  edges: Array<LogEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LogEdge = {
  __typename?: "LogEdge";
  node: Log;
  cursor: Scalars["String"]["output"];
};

export type CreateLogInput = {
  source?: InputMaybe<LogBackofficeSource>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<LogBackofficeLevel>;
  title: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
};

export type LogOrder = {
  field: LogOrderField;
  direction: ConnectionOrderDirection;
};

export type LogFilter = {
  title?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  source?: InputMaybe<EnumFilterInput>;
  level?: InputMaybe<EnumFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum LogOrderField {
  Title = "TITLE",
  Level = "LEVEL",
  Source = "SOURCE",
  CreatedAt = "CREATED_AT",
}

export enum LogBackofficeSource {
  Unknown = "UNKNOWN",
  Server = "SERVER",
  Backoffice = "BACKOFFICE",
  App = "APP",
}

export enum LogBackofficeLevel {
  Info = "INFO",
  Warning = "WARNING",
  Error = "ERROR",
}

export type Statistics = {
  __typename?: "Statistics";
  totalUsers: Scalars["Int"]["output"];
};

export type StatisticsTotalUsersArgs = {
  role?: InputMaybe<UserBackofficeRole>;
};

export type RegisterUserDeviceInput = {
  deviceId: Scalars["ID"]["input"];
  deviceOs: DeviceOs;
  pushNotificationsToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type RegisteredUserDevice = {
  __typename?: "RegisteredUserDevice";
  id: Scalars["ID"]["output"];
  userId: Scalars["ID"]["output"];
  deviceId: Scalars["ID"]["output"];
  deviceOs: DeviceOs;
  createdAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum DeviceOs {
  Ios = "IOS",
  Android = "ANDROID",
}

export type PublicConfig = {
  __typename?: "PublicConfig";
  id: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  createdBy: Scalars["ID"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: Scalars["ID"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<Scalars["ID"]["output"]>;
};

export type PublicConfigConnection = {
  __typename?: "PublicConfigConnection";
  edges: Array<PublicConfigEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PublicConfigEdge = {
  __typename?: "PublicConfigEdge";
  node: PublicConfig;
  cursor: Scalars["String"]["output"];
};

export type CreatePublicConfigInput = {
  id: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type UpdatePublicConfigInput = {
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type PublicConfigOrder = {
  field: PublicConfigOrderField;
  direction: ConnectionOrderDirection;
};

export type PublicConfigFilter = {
  id?: InputMaybe<StringFilterInput>;
  value?: InputMaybe<StringFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum PublicConfigOrderField {
  Id = "ID",
  Value = "VALUE",
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
  DeletedAt = "DELETED_AT",
  DeletedBy = "DELETED_BY",
}

export type TapReport = {
  __typename?: "TapReport";
  id: Scalars["ID"]["output"];
  category: Tap_Report_Category;
  description?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  tap: Tap;
  reportedByUser: TapUser;
};

export type TapReportConnection = {
  __typename?: "TapReportConnection";
  edges: Array<TapReportEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TapReportEdge = {
  __typename?: "TapReportEdge";
  node: TapReport;
  cursor: Scalars["String"]["output"];
};

export type TapReportOrder = {
  field: TapReportOrderField;
  direction: ConnectionOrderDirection;
};

export type TapReportFilter = {
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum TapReportOrderField {
  CreatedAt = "CREATED_AT",
}

export type Tap = {
  __typename?: "Tap";
  reports: Array<TapReport>;
  id: Scalars["ID"]["output"];
  tappedAt: Scalars["DateTime"]["output"];
  comment?: Maybe<Scalars["String"]["output"]>;
  rate: Scalars["Int"]["output"];
  media: Array<TapMedia>;
  business: TapBusiness;
  reportStatus: TapReportStatus;
  reportCount: Scalars["Int"]["output"];
  lastReportDate?: Maybe<Scalars["DateTime"]["output"]>;
  tappedByUser: TapUser;
};

export enum Tap_Report_Category {
  Spam = "SPAM",
  Harassment = "HARASSMENT",
  HateSpeech = "HATE_SPEECH",
  MisleadingInformation = "MISLEADING_INFORMATION",
  InappropriateContent = "INAPPROPRIATE_CONTENT",
  SelfHarm = "SELF_HARM",
  Other = "OTHER",
}

export type TapReportsData = {
  __typename?: "TapReportsData";
  reportStatus: TapReportStatus;
  tapId: Scalars["ID"]["output"];
  lastReportedDate?: Maybe<Scalars["DateTime"]["output"]>;
  reportCount: Scalars["Int"]["output"];
  approvedById?: Maybe<Scalars["ID"]["output"]>;
  approvedAt?: Maybe<Scalars["DateTime"]["output"]>;
  lastRejectedById?: Maybe<Scalars["ID"]["output"]>;
  lastRejectedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type TapMedia = {
  __typename?: "TapMedia";
  storageKey: Scalars["String"]["output"];
};

export type TapBusiness = {
  __typename?: "TapBusiness";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  address?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  openingHours: Array<TapBusinessOpeningHours>;
};

export type TapBusinessOpeningHours = {
  __typename?: "TapBusinessOpeningHours";
  openDay: Scalars["Int"]["output"];
  openTime?: Maybe<Scalars["String"]["output"]>;
  closeDay?: Maybe<Scalars["Int"]["output"]>;
  closeTime?: Maybe<Scalars["String"]["output"]>;
};

export type TapConnection = {
  __typename?: "TapConnection";
  edges: Array<TapEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TapEdge = {
  __typename?: "TapEdge";
  node: Tap;
  cursor: Scalars["String"]["output"];
};

export type TapOrder = {
  field: TapOrderField;
  direction: ConnectionOrderDirection;
};

export type TapFilter = {
  reportedOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reportStatus?: InputMaybe<TapReportStatus>;
};

export enum TapOrderField {
  BusinessName = "BUSINESS_NAME",
  BusinessAddress = "BUSINESS_ADDRESS",
  LastReportedDate = "LAST_REPORTED_DATE",
  ReportedByUsername = "REPORTED_BY_USERNAME",
  ReportCount = "REPORT_COUNT",
  ReportStatus = "REPORT_STATUS",
}

export enum TapReportStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  NotReported = "NOT_REPORTED",
}

export type TapUser = {
  __typename?: "TapUser";
  id: Scalars["ID"]["output"];
  profile?: Maybe<TapUserProfile>;
};

export type TapUserProfile = {
  __typename?: "TapUserProfile";
  username: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  picture?: Maybe<TapUserProfilePicture>;
};

export type TapUserProfilePicture = {
  __typename?: "TapUserProfilePicture";
  storageKey: Scalars["String"]["output"];
};

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
  image?: InputMaybe<Scalars["Upload"]["input"]>;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName?: string | null;
    email?: string | null;
    status?: UserBackofficeStatus | null;
    role?: UserBackofficeRole | null;
    nickName?: string | null;
    area?: string | null;
    birthday?: any | null;
    bio?: string | null;
    country?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type CreatePublicConfigMutationVariables = Exact<{
  input: CreatePublicConfigInput;
}>;

export type CreatePublicConfigMutation = {
  __typename?: "Mutation";
  createPublicConfig?: {
    __typename?: "PublicConfig";
    id: string;
    value: string;
    createdAt: any;
    createdBy: string;
    updatedBy: string;
    updatedAt: any;
  } | null;
};

export type ApproveTapReportsMutationVariables = Exact<{
  tapId: Scalars["ID"]["input"];
}>;

export type ApproveTapReportsMutation = {
  __typename?: "Mutation";
  approveTapReports: {__typename?: "TapReportsData"; tapId: string};
};

export type DeleteFileMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteFileMutation = {__typename?: "Mutation"; deleteFile: boolean};

export type CreateLogMutationVariables = Exact<{
  log: CreateLogInput;
}>;

export type CreateLogMutation = {__typename?: "Mutation"; createLog?: {__typename?: "Log"; id: string} | null};

export type DownloadUrlMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DownloadUrlMutation = {
  __typename?: "Mutation";
  downloadUrl: {__typename?: "FileDownloadUrl"; id: string; url: string; expirationDate?: any | null};
};

export type AuthenticateMutationVariables = Exact<{
  type: AuthenticationBackofficeType;
  identity: Scalars["String"]["input"];
  payload: AuthenticationPayload;
}>;

export type AuthenticateMutation = {
  __typename?: "Mutation";
  authenticate?: {
    __typename?: "AccessToken";
    userId: string;
    userRole: UserBackofficeRole;
    token?: string | null;
    refreshToken?: string | null;
    expiresAt?: any | null;
    authorizationType: AuthorizationType;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteUserMutation = {__typename?: "Mutation"; deleteUser: boolean};

export type DeletePublicConfigMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeletePublicConfigMutation = {__typename?: "Mutation"; deletePublicConfig: boolean};

export type FinishUploadMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FinishUploadMutation = {
  __typename?: "Mutation";
  finishUpload: {
    __typename?: "File";
    id: string;
    deviceId?: string | null;
    userId?: string | null;
    name: string;
    extension?: string | null;
    isPrivate?: boolean | null;
    contentType?: string | null;
    notes?: string | null;
    status: FileBackofficeStatus;
    statusDate: any;
    statusMessage?: string | null;
    storageType: FileBackofficeStorageType;
    storageId?: string | null;
    storageUploadUrl?: string | null;
    storageDownloadUrl?: string | null;
    thumbnailUrl?: string | null;
    createdAt: any;
    createdBy: string;
    updatedAt: any;
    updatedBy: string;
    deletedAt?: any | null;
    deletedBy?: string | null;
    privateData?: {
      __typename?: "FilePrivateData";
      userId?: string | null;
      status: FileBackofficePrivateStatus;
      statusDate: any;
      statusMessage?: string | null;
      notes?: string | null;
    } | null;
  };
};

export type LogsQueryVariables = Exact<{
  text?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  source?: InputMaybe<Array<LogBackofficeSource> | LogBackofficeSource>;
  level?: InputMaybe<Array<LogBackofficeLevel> | LogBackofficeLevel>;
  orderBy?: InputMaybe<LogOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type LogsQuery = {
  __typename?: "Query";
  logs: {
    __typename?: "LogConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "LogEdge";
      cursor: string;
      node: {
        __typename?: "Log";
        id: string;
        source: LogBackofficeSource;
        level: LogBackofficeLevel;
        title: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      };
    }>;
    pageInfo: {__typename?: "ConnectionPageInfo"; hasNextPage: boolean; endCursor?: string | null};
  };
};

export type FragmentFileFragment = {
  __typename?: "File";
  id: string;
  deviceId?: string | null;
  userId?: string | null;
  name: string;
  extension?: string | null;
  isPrivate?: boolean | null;
  contentType?: string | null;
  notes?: string | null;
  status: FileBackofficeStatus;
  statusDate: any;
  statusMessage?: string | null;
  storageType: FileBackofficeStorageType;
  storageId?: string | null;
  storageUploadUrl?: string | null;
  storageDownloadUrl?: string | null;
  thumbnailUrl?: string | null;
  createdAt: any;
  createdBy: string;
  updatedAt: any;
  updatedBy: string;
  deletedAt?: any | null;
  deletedBy?: string | null;
  privateData?: {
    __typename?: "FilePrivateData";
    userId?: string | null;
    status: FileBackofficePrivateStatus;
    statusDate: any;
    statusMessage?: string | null;
    notes?: string | null;
  } | null;
};

export type FilesQueryVariables = Exact<{
  text?: InputMaybe<Scalars["String"]["input"]>;
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  extension?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<FileBackofficeStatus> | FileBackofficeStatus>;
  orderBy?: InputMaybe<FileOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type FilesQuery = {
  __typename?: "Query";
  files: {
    __typename?: "FileConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "FileEdge";
      cursor: string;
      node: {
        __typename?: "File";
        id: string;
        deviceId?: string | null;
        userId?: string | null;
        name: string;
        extension?: string | null;
        isPrivate?: boolean | null;
        contentType?: string | null;
        notes?: string | null;
        status: FileBackofficeStatus;
        statusDate: any;
        statusMessage?: string | null;
        storageType: FileBackofficeStorageType;
        storageId?: string | null;
        storageUploadUrl?: string | null;
        storageDownloadUrl?: string | null;
        thumbnailUrl?: string | null;
        createdAt: any;
        createdBy: string;
        updatedAt: any;
        updatedBy: string;
        deletedAt?: any | null;
        deletedBy?: string | null;
        privateData?: {
          __typename?: "FilePrivateData";
          userId?: string | null;
          status: FileBackofficePrivateStatus;
          statusDate: any;
          statusMessage?: string | null;
          notes?: string | null;
        } | null;
      };
    }>;
    pageInfo: {__typename?: "ConnectionPageInfo"; endCursor?: string | null; hasNextPage: boolean};
  };
};

export type FileQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FileQuery = {
  __typename?: "Query";
  file?: {
    __typename?: "File";
    id: string;
    deviceId?: string | null;
    userId?: string | null;
    name: string;
    extension?: string | null;
    isPrivate?: boolean | null;
    contentType?: string | null;
    notes?: string | null;
    status: FileBackofficeStatus;
    statusDate: any;
    statusMessage?: string | null;
    storageType: FileBackofficeStorageType;
    storageId?: string | null;
    storageUploadUrl?: string | null;
    storageDownloadUrl?: string | null;
    thumbnailUrl?: string | null;
    createdAt: any;
    createdBy: string;
    updatedAt: any;
    updatedBy: string;
    deletedAt?: any | null;
    deletedBy?: string | null;
    privateData?: {
      __typename?: "FilePrivateData";
      userId?: string | null;
      status: FileBackofficePrivateStatus;
      statusDate: any;
      statusMessage?: string | null;
      notes?: string | null;
    } | null;
  } | null;
};

export type RefreshTokensMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type RefreshTokensMutation = {
  __typename?: "Mutation";
  refreshTokens?: {
    __typename?: "AccessToken";
    token?: string | null;
    refreshToken?: string | null;
    expiresAt?: any | null;
    authorizationType: AuthorizationType;
  } | null;
};

export type PublicConfigsQueryVariables = Exact<{
  filter?: InputMaybe<PublicConfigFilter>;
  orderBy?: InputMaybe<PublicConfigOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PublicConfigsQuery = {
  __typename?: "Query";
  publicConfigs: {
    __typename?: "PublicConfigConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "PublicConfigEdge";
      node: {
        __typename?: "PublicConfig";
        id: string;
        value: string;
        updatedBy: string;
        createdBy: string;
        updatedAt: any;
        createdAt: any;
      };
    }>;
  };
};

export type UpdatePasswordMutationVariables = Exact<{
  oldPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}>;

export type UpdatePasswordMutation = {__typename?: "Mutation"; updatePassword?: any | null};

export type StatisticsQueryVariables = Exact<{[key: string]: never}>;

export type StatisticsQuery = {__typename?: "Query"; statistics: {__typename?: "Statistics"; totalUsers: number}};

export type MeQueryVariables = Exact<{[key: string]: never}>;

export type MeQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName?: string | null;
    email?: string | null;
    status?: UserBackofficeStatus | null;
    role?: UserBackofficeRole | null;
    imageUrl?: string | null;
    nickName?: string | null;
    area?: string | null;
    birthday?: any | null;
    bio?: string | null;
    country?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type RejectTapReportsMutationVariables = Exact<{
  tapId: Scalars["ID"]["input"];
}>;

export type RejectTapReportsMutation = {
  __typename?: "Mutation";
  rejectTapReports: {__typename?: "TapReportsData"; tapId: string};
};

export type UpdateFileMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  file: UpdateFileInput;
}>;

export type UpdateFileMutation = {
  __typename?: "Mutation";
  updateFile?: {
    __typename?: "File";
    id: string;
    deviceId?: string | null;
    userId?: string | null;
    name: string;
    extension?: string | null;
    isPrivate?: boolean | null;
    contentType?: string | null;
    notes?: string | null;
    status: FileBackofficeStatus;
    statusDate: any;
    statusMessage?: string | null;
    storageType: FileBackofficeStorageType;
    storageId?: string | null;
    storageUploadUrl?: string | null;
    storageDownloadUrl?: string | null;
    thumbnailUrl?: string | null;
    createdAt: any;
    createdBy: string;
    updatedAt: any;
    updatedBy: string;
    deletedAt?: any | null;
    deletedBy?: string | null;
    privateData?: {
      __typename?: "FilePrivateData";
      userId?: string | null;
      status: FileBackofficePrivateStatus;
      statusDate: any;
      statusMessage?: string | null;
      notes?: string | null;
    } | null;
  } | null;
};

export type UpdatePublicConfigMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: UpdatePublicConfigInput;
}>;

export type UpdatePublicConfigMutation = {
  __typename?: "Mutation";
  updatePublicConfig?: {
    __typename?: "PublicConfig";
    id: string;
    value: string;
    createdAt: any;
    createdBy: string;
    updatedBy: string;
    updatedAt: any;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  user: UpdateUserInput;
  image?: InputMaybe<Scalars["Upload"]["input"]>;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName?: string | null;
    email?: string | null;
    status?: UserBackofficeStatus | null;
    role?: UserBackofficeRole | null;
    imageUrl?: string | null;
    nickName?: string | null;
    area?: string | null;
    birthday?: any | null;
    bio?: string | null;
    country?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type UploadOverwriteUrlMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UploadOverwriteUrlMutation = {
  __typename?: "Mutation";
  uploadOverwriteUrl: {
    __typename?: "FileUploadUrl";
    id: string;
    method: string;
    url: string;
    headers?: any | null;
    fields?: any | null;
    contentType: string;
    expirationDate: any;
  };
};

export type TapsQueryVariables = Exact<{
  filter?: InputMaybe<TapFilter>;
  orderBy?: InputMaybe<TapOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type TapsQuery = {
  __typename?: "Query";
  taps: {
    __typename?: "TapConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "TapEdge";
      node: {
        __typename?: "Tap";
        id: string;
        tappedAt: any;
        comment?: string | null;
        rate: number;
        reportStatus: TapReportStatus;
        reportCount: number;
        lastReportDate?: any | null;
        reports: Array<{
          __typename?: "TapReport";
          id: string;
          category: Tap_Report_Category;
          description?: string | null;
          createdAt: any;
          reportedByUser: {
            __typename?: "TapUser";
            id: string;
            profile?: {
              __typename?: "TapUserProfile";
              username: string;
              firstName?: string | null;
              lastName?: string | null;
              picture?: {__typename?: "TapUserProfilePicture"; storageKey: string} | null;
            } | null;
          };
        }>;
        media: Array<{__typename?: "TapMedia"; storageKey: string}>;
        business: {
          __typename?: "TapBusiness";
          id: string;
          name: string;
          address?: string | null;
          type?: string | null;
          openingHours: Array<{
            __typename?: "TapBusinessOpeningHours";
            openDay: number;
            openTime?: string | null;
            closeDay?: number | null;
            closeTime?: string | null;
          }>;
        };
        tappedByUser: {
          __typename?: "TapUser";
          id: string;
          profile?: {
            __typename?: "TapUserProfile";
            username: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: {__typename?: "TapUserProfilePicture"; storageKey: string} | null;
          } | null;
        };
      };
    }>;
  };
};

export type UsersQueryVariables = Exact<{
  text?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<UserBackofficeStatus> | UserBackofficeStatus>;
  role?: InputMaybe<Array<UserBackofficeRole> | UserBackofficeRole>;
  orderBy?: InputMaybe<UserOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type UsersQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UserConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "UserEdge";
      node: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName?: string | null;
        email?: string | null;
        status?: UserBackofficeStatus | null;
        role?: UserBackofficeRole | null;
        imageUrl?: string | null;
        nickName?: string | null;
        area?: string | null;
        birthday?: any | null;
        bio?: string | null;
        country?: string | null;
        imageData?: any | null;
        createdAt: any;
        updatedAt: any;
      };
    }>;
  };
};

export type VersionQueryVariables = Exact<{[key: string]: never}>;

export type VersionQuery = {__typename?: "Query"; version?: string | null};

export type UploadUrlMutationVariables = Exact<{
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  extension?: InputMaybe<Scalars["String"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  isPrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
  tags?: InputMaybe<Scalars["JSONObject"]["input"]>;
}>;

export type UploadUrlMutation = {
  __typename?: "Mutation";
  uploadUrl: {
    __typename?: "FileUploadUrl";
    id: string;
    method: string;
    url: string;
    headers?: any | null;
    fields?: any | null;
    contentType: string;
    expirationDate: any;
  };
};

export const FragmentFileFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<FragmentFileFragment, unknown>;
export const CreateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "CreateUser"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "user"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CreateUserInput"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "image"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Upload"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "createUser"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "user"},
                value: {kind: "Variable", name: {kind: "Name", value: "user"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "image"},
                value: {kind: "Variable", name: {kind: "Name", value: "image"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                {kind: "Field", name: {kind: "Name", value: "email"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "role"}},
                {kind: "Field", name: {kind: "Name", value: "nickName"}},
                {kind: "Field", name: {kind: "Name", value: "area"}},
                {kind: "Field", name: {kind: "Name", value: "birthday"}},
                {kind: "Field", name: {kind: "Name", value: "bio"}},
                {kind: "Field", name: {kind: "Name", value: "country"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const CreatePublicConfigDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "CreatePublicConfig"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "input"}},
          type: {
            kind: "NonNullType",
            type: {kind: "NamedType", name: {kind: "Name", value: "CreatePublicConfigInput"}},
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "createPublicConfig"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "publicConfig"},
                value: {kind: "Variable", name: {kind: "Name", value: "input"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "value"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePublicConfigMutation, CreatePublicConfigMutationVariables>;
export const ApproveTapReportsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "approveTapReports"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "tapId"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "approveTapReports"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "tapId"},
                value: {kind: "Variable", name: {kind: "Name", value: "tapId"}},
              },
            ],
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "tapId"}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApproveTapReportsMutation, ApproveTapReportsMutationVariables>;
export const DeleteFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DeleteFile"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "deleteFile"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFileMutation, DeleteFileMutationVariables>;
export const CreateLogDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "CreateLog"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "log"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CreateLogInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "createLog"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "log"},
                value: {kind: "Variable", name: {kind: "Name", value: "log"}},
              },
            ],
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLogMutation, CreateLogMutationVariables>;
export const DownloadUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DownloadUrl"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "downloadUrl"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "url"}},
                {kind: "Field", name: {kind: "Name", value: "expirationDate"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadUrlMutation, DownloadUrlMutationVariables>;
export const AuthenticateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "Authenticate"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "type"}},
          type: {
            kind: "NonNullType",
            type: {kind: "NamedType", name: {kind: "Name", value: "AuthenticationBackofficeType"}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "identity"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "payload"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "AuthenticationPayload"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "authenticate"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "type"},
                value: {kind: "Variable", name: {kind: "Name", value: "type"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "identity"},
                value: {kind: "Variable", name: {kind: "Name", value: "identity"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "payload"},
                value: {kind: "Variable", name: {kind: "Name", value: "payload"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "userRole"}},
                {kind: "Field", name: {kind: "Name", value: "token"}},
                {kind: "Field", name: {kind: "Name", value: "refreshToken"}},
                {kind: "Field", name: {kind: "Name", value: "expiresAt"}},
                {kind: "Field", name: {kind: "Name", value: "authorizationType"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthenticateMutation, AuthenticateMutationVariables>;
export const DeleteUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DeleteUser"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "deleteUser"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeletePublicConfigDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DeletePublicConfig"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "deletePublicConfig"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePublicConfigMutation, DeletePublicConfigMutationVariables>;
export const FinishUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "FinishUpload"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "finishUpload"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FragmentFile"}}],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinishUploadMutation, FinishUploadMutationVariables>;
export const LogsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Logs"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "text"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "startDate"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "endDate"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "source"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "LogBackofficeSource"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "level"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "LogBackofficeLevel"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "LogOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "logs"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "text"},
                value: {kind: "Variable", name: {kind: "Name", value: "text"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "source"},
                value: {kind: "Variable", name: {kind: "Name", value: "source"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "level"},
                value: {kind: "Variable", name: {kind: "Name", value: "level"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "startDate"},
                value: {kind: "Variable", name: {kind: "Name", value: "startDate"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "endDate"},
                value: {kind: "Variable", name: {kind: "Name", value: "endDate"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "source"}},
                            {kind: "Field", name: {kind: "Name", value: "level"}},
                            {kind: "Field", name: {kind: "Name", value: "title"}},
                            {kind: "Field", name: {kind: "Name", value: "description"}},
                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                          ],
                        },
                      },
                      {kind: "Field", name: {kind: "Name", value: "cursor"}},
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: {kind: "Name", value: "pageInfo"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "hasNextPage"}},
                      {kind: "Field", name: {kind: "Name", value: "endCursor"}},
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogsQuery, LogsQueryVariables>;
export const FilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Files"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "text"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "deviceId"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "userId"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "path"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "extension"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "notes"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "status"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "FileBackofficeStatus"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "FileOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "files"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "text"},
                value: {kind: "Variable", name: {kind: "Name", value: "text"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "deviceId"},
                value: {kind: "Variable", name: {kind: "Name", value: "deviceId"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "userId"},
                value: {kind: "Variable", name: {kind: "Name", value: "userId"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "path"},
                value: {kind: "Variable", name: {kind: "Name", value: "path"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "name"},
                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "extension"},
                value: {kind: "Variable", name: {kind: "Name", value: "extension"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "notes"},
                value: {kind: "Variable", name: {kind: "Name", value: "notes"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "status"},
                value: {kind: "Variable", name: {kind: "Name", value: "status"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FragmentFile"}}],
                        },
                      },
                      {kind: "Field", name: {kind: "Name", value: "cursor"}},
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: {kind: "Name", value: "pageInfo"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "endCursor"}},
                      {kind: "Field", name: {kind: "Name", value: "hasNextPage"}},
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilesQuery, FilesQueryVariables>;
export const FileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "File"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "file"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FragmentFile"}}],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileQuery, FileQueryVariables>;
export const RefreshTokensDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "RefreshTokens"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "token"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "refreshTokens"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "token"},
                value: {kind: "Variable", name: {kind: "Name", value: "token"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "token"}},
                {kind: "Field", name: {kind: "Name", value: "refreshToken"}},
                {kind: "Field", name: {kind: "Name", value: "expiresAt"}},
                {kind: "Field", name: {kind: "Name", value: "authorizationType"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshTokensMutation, RefreshTokensMutationVariables>;
export const PublicConfigsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "PublicConfigs"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "filter"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "PublicConfigFilter"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "PublicConfigOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "publicConfigs"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "filter"},
                value: {kind: "Variable", name: {kind: "Name", value: "filter"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "value"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                            {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicConfigsQuery, PublicConfigsQueryVariables>;
export const UpdatePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdatePassword"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "oldPassword"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "newPassword"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updatePassword"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "oldPassword"},
                value: {kind: "Variable", name: {kind: "Name", value: "oldPassword"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "newPassword"},
                value: {kind: "Variable", name: {kind: "Name", value: "newPassword"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const StatisticsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Statistics"},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "statistics"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [{kind: "Field", name: {kind: "Name", value: "totalUsers"}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatisticsQuery, StatisticsQueryVariables>;
export const MeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Me"},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "me"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                {kind: "Field", name: {kind: "Name", value: "email"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "role"}},
                {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                {kind: "Field", name: {kind: "Name", value: "nickName"}},
                {kind: "Field", name: {kind: "Name", value: "area"}},
                {kind: "Field", name: {kind: "Name", value: "birthday"}},
                {kind: "Field", name: {kind: "Name", value: "bio"}},
                {kind: "Field", name: {kind: "Name", value: "country"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const RejectTapReportsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "rejectTapReports"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "tapId"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "rejectTapReports"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "tapId"},
                value: {kind: "Variable", name: {kind: "Name", value: "tapId"}},
              },
            ],
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "tapId"}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RejectTapReportsMutation, RejectTapReportsMutationVariables>;
export const UpdateFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdateFile"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "file"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UpdateFileInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updateFile"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "file"},
                value: {kind: "Variable", name: {kind: "Name", value: "file"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FragmentFile"}}],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFileMutation, UpdateFileMutationVariables>;
export const UpdatePublicConfigDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdatePublicConfig"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "input"}},
          type: {
            kind: "NonNullType",
            type: {kind: "NamedType", name: {kind: "Name", value: "UpdatePublicConfigInput"}},
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updatePublicConfig"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "publicConfig"},
                value: {kind: "Variable", name: {kind: "Name", value: "input"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "value"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePublicConfigMutation, UpdatePublicConfigMutationVariables>;
export const UpdateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdateUser"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "user"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UpdateUserInput"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "image"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Upload"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updateUser"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "user"},
                value: {kind: "Variable", name: {kind: "Name", value: "user"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "image"},
                value: {kind: "Variable", name: {kind: "Name", value: "image"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                {kind: "Field", name: {kind: "Name", value: "email"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "role"}},
                {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                {kind: "Field", name: {kind: "Name", value: "nickName"}},
                {kind: "Field", name: {kind: "Name", value: "area"}},
                {kind: "Field", name: {kind: "Name", value: "birthday"}},
                {kind: "Field", name: {kind: "Name", value: "bio"}},
                {kind: "Field", name: {kind: "Name", value: "country"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadOverwriteUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UploadOverwriteUrl"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "uploadOverwriteUrl"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "method"}},
                {kind: "Field", name: {kind: "Name", value: "url"}},
                {kind: "Field", name: {kind: "Name", value: "headers"}},
                {kind: "Field", name: {kind: "Name", value: "fields"}},
                {kind: "Field", name: {kind: "Name", value: "contentType"}},
                {kind: "Field", name: {kind: "Name", value: "expirationDate"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadOverwriteUrlMutation, UploadOverwriteUrlMutationVariables>;
export const TapsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Taps"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "filter"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "TapFilter"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "TapOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "taps"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "filter"},
                value: {kind: "Variable", name: {kind: "Name", value: "filter"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "reports"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {kind: "Field", name: {kind: "Name", value: "id"}},
                                  {kind: "Field", name: {kind: "Name", value: "category"}},
                                  {kind: "Field", name: {kind: "Name", value: "description"}},
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "reportedByUser"},
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {kind: "Field", name: {kind: "Name", value: "id"}},
                                        {
                                          kind: "Field",
                                          name: {kind: "Name", value: "profile"},
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {kind: "Field", name: {kind: "Name", value: "username"}},
                                              {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                              {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                              {
                                                kind: "Field",
                                                name: {kind: "Name", value: "picture"},
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {kind: "Field", name: {kind: "Name", value: "storageKey"}},
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                ],
                              },
                            },
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "tappedAt"}},
                            {kind: "Field", name: {kind: "Name", value: "comment"}},
                            {kind: "Field", name: {kind: "Name", value: "rate"}},
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "media"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{kind: "Field", name: {kind: "Name", value: "storageKey"}}],
                              },
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "business"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {kind: "Field", name: {kind: "Name", value: "id"}},
                                  {kind: "Field", name: {kind: "Name", value: "name"}},
                                  {kind: "Field", name: {kind: "Name", value: "address"}},
                                  {kind: "Field", name: {kind: "Name", value: "type"}},
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "openingHours"},
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {kind: "Field", name: {kind: "Name", value: "openDay"}},
                                        {kind: "Field", name: {kind: "Name", value: "openTime"}},
                                        {kind: "Field", name: {kind: "Name", value: "closeDay"}},
                                        {kind: "Field", name: {kind: "Name", value: "closeTime"}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {kind: "Field", name: {kind: "Name", value: "reportStatus"}},
                            {kind: "Field", name: {kind: "Name", value: "reportCount"}},
                            {kind: "Field", name: {kind: "Name", value: "lastReportDate"}},
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "tappedByUser"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {kind: "Field", name: {kind: "Name", value: "id"}},
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "profile"},
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {kind: "Field", name: {kind: "Name", value: "username"}},
                                        {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                        {kind: "Field", name: {kind: "Name", value: "lastName"}},
                                        {
                                          kind: "Field",
                                          name: {kind: "Name", value: "picture"},
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{kind: "Field", name: {kind: "Name", value: "storageKey"}}],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TapsQuery, TapsQueryVariables>;
export const UsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Users"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "text"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "firstName"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "lastName"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "status"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UserBackofficeStatus"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "role"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UserBackofficeRole"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "UserOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "users"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "text"},
                value: {kind: "Variable", name: {kind: "Name", value: "text"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "firstName"},
                value: {kind: "Variable", name: {kind: "Name", value: "firstName"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "lastName"},
                value: {kind: "Variable", name: {kind: "Name", value: "lastName"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "email"},
                value: {kind: "Variable", name: {kind: "Name", value: "email"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "status"},
                value: {kind: "Variable", name: {kind: "Name", value: "status"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "role"},
                value: {kind: "Variable", name: {kind: "Name", value: "role"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "firstName"}},
                            {kind: "Field", name: {kind: "Name", value: "lastName"}},
                            {kind: "Field", name: {kind: "Name", value: "email"}},
                            {kind: "Field", name: {kind: "Name", value: "status"}},
                            {kind: "Field", name: {kind: "Name", value: "role"}},
                            {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                            {kind: "Field", name: {kind: "Name", value: "nickName"}},
                            {kind: "Field", name: {kind: "Name", value: "area"}},
                            {kind: "Field", name: {kind: "Name", value: "birthday"}},
                            {kind: "Field", name: {kind: "Name", value: "bio"}},
                            {kind: "Field", name: {kind: "Name", value: "country"}},
                            {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "imageData"},
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: {kind: "Name", value: "height"},
                                  value: {kind: "IntValue", value: "128"},
                                },
                                {
                                  kind: "Argument",
                                  name: {kind: "Name", value: "width"},
                                  value: {kind: "IntValue", value: "128"},
                                },
                              ],
                            },
                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const VersionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Version"},
      selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "version"}}]},
    },
  ],
} as unknown as DocumentNode<VersionQuery, VersionQueryVariables>;
export const UploadUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UploadUrl"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "deviceId"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "path"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "extension"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "contentType"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "notes"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "isPrivate"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "tags"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "JSONObject"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "uploadUrl"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "deviceId"},
                value: {kind: "Variable", name: {kind: "Name", value: "deviceId"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "path"},
                value: {kind: "Variable", name: {kind: "Name", value: "path"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "name"},
                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "extension"},
                value: {kind: "Variable", name: {kind: "Name", value: "extension"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "contentType"},
                value: {kind: "Variable", name: {kind: "Name", value: "contentType"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "notes"},
                value: {kind: "Variable", name: {kind: "Name", value: "notes"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "isPrivate"},
                value: {kind: "Variable", name: {kind: "Name", value: "isPrivate"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "tags"},
                value: {kind: "Variable", name: {kind: "Name", value: "tags"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "method"}},
                {kind: "Field", name: {kind: "Name", value: "url"}},
                {kind: "Field", name: {kind: "Name", value: "headers"}},
                {kind: "Field", name: {kind: "Name", value: "fields"}},
                {kind: "Field", name: {kind: "Name", value: "contentType"}},
                {kind: "Field", name: {kind: "Name", value: "expirationDate"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadUrlMutation, UploadUrlMutationVariables>;
