import {GraphQLClient, RequestOptions, Variables} from "graphql-request";

import {AppConfig} from "./app-config";
import {multipartExtractFiles} from "./utils/multipart-files-middleware";
let client: GraphQLClient;

export async function serverRequest<T, V extends Variables = Variables>(options: RequestOptions<V, T>): Promise<T> {
  const url: string = AppConfig.Settings.Server.graphql.apiClient.baseUrl;
  if (!url) {
    throw new Error("Missing env Variable NEXT_PUBLIC_SERVER_BASE_URL");
  }
  if (!client) {
    client = new GraphQLClient(url, {requestMiddleware: multipartExtractFiles});
  }
  return client.request({...options, url});
}
